import * as colors from '../constants/colors'

let Colors = require('material-ui/styles/colors');
let ColorManipulator = require('material-ui/utils/colorManipulator');
let Spacing = require('material-ui/styles/spacing');

export default {
  spacing: Spacing,
  fontFamily: 'Lato, sans-serif',
  palette: {
    primary1Color: Colors.cyan700,
    primary2Color: Colors.cyan700,
    primary3Color: Colors.lightBlack,
    accent1Color: Colors.grey100,
    accent2Color: Colors.grey100,
    accent3Color: Colors.grey500,
    textColor: Colors.darkBlack,
    alternateTextColor: Colors.white,
    canvasColor: Colors.white,
    borderColor: Colors.grey300,
    disabledColor: ColorManipulator.fade(Colors.darkBlack, 0.3),
  },
};