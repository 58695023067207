import * as storage from '../persistance/storage';
import * as authConstants from '../constants/auth';

export default function isLoggedIn() {

	let token = storage.get('token');
	let expiry = storage.get('tokenExpiry');

	if(!token || token.length < 24) {
		return false;
	}
	if(!expiry || expiry < Date.now()) {
		return false;
	}
	return true;
}

export function saveLogin(token) {
	storage.put('token', token);
	storage.put('tokenExpiry', Date.now() + authConstants.LOGOUT_TIME);
}

export function logout() {
	storage.clear();
}