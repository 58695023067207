import React  from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import Calendar from './Calendar';


class Dashboard extends React.Component {

	static propTypes = {
    location: PropTypes.object
  };

  static contextTypes = {
    store: PropTypes.any,
    router: PropTypes.object.isRequired
  };

	constructor(props) {
		super(props);
	}

	render() {
		return <div className='Dashboard'>
			<div className='dashboardTitle'>Sam Eats the House</div>
			<Calendar />
		</div>;
	}
}
export default Dashboard;
