import * as constants from '../constants/actions';

const initialState = {
  alertOpen: false,
  alertText: '',
  modalResize: 0,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case constants.COMMON_ALERT:
      return commonOpenAlert(state, action);
    case constants.COMMON_CLOSE_ALERT:
      return commonCloseAlert(state, action);
    case constants.COMMON_MODAL_RESIZE:
      return commonModalResize(state, action);
    default:
      return state;
  }
}

function commonOpenAlert(state, action) {
  return Object.assign({}, state, {
    alertOpen: true,
    alertText: action.text,
  });
}

function commonCloseAlert(state, action) {
  return Object.assign({}, state, {
    alertOpen: false,
    alertText: '',
  });
}

function commonModalResize(state, action) {
  return Object.assign({}, state, {
    modalResize: state.modalResize + 0.1,
  });
}