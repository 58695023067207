import * as constants from '../constants/actions';

const initialState = {
  pageTitle: 'Synchromatik',
  studentTitle: 'Synchromatik',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case constants.HEADER_TITLE:
      return headerTitle(state, action);
    default:
      return state;
  }
}

function headerTitle(state, action) {
  return Object.assign({}, state, {
    pageTitle: action.title
  });
}

