import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import Lightbox from 'react-images';


class Calendar extends React.Component {

	static propTypes = {
    location: PropTypes.object
  };

  static contextTypes = {
    store: PropTypes.any,
    router: PropTypes.object.isRequired
  };

	constructor(props) {
		super(props);
		this.state = {
			lbOpen: false,
			lbIndex: 0,
			lbImages: [
				{
					src: '/img/0426.jpg',
					caption: 'April 26th - Feline storage'
				},
				{
					src: '/img/0427.jpg',
					caption: 'April 27th - Fresh fruit with a side of cat food'
				},
				{
					src: '/img/0428.jpg',
					caption: 'April 28th - First time this thing\'s been functional'
				},
				{
					src: '/img/0429.jpg',
					caption: 'April 29th - Breakfast in bed'
				},
				{
					src: '/img/0430.jpg',
					caption: 'April 30th - Dinner fresh out of the oven'
				},
				{
					src: '/img/0501.jpg',
					caption: 'May 1st - Copy cat'
				},
				{
					src: '/img/0502.jpg',
					caption: 'May 2nd - Coffee table kitty'
				},
				{
					src: '/img/0503.jpg',
					caption: 'May 3rd - Eating in front of the dogs'
				},
				{
					src: '/img/0504.jpg',
					caption: 'May 4th - Food on top of food'
				},
				{
					src: '/img/0505.jpg',
					caption: 'May 5th - Clean eating is healthy eating'
				},
				{
					src: '/img/0506.jpg',
					caption: 'May 6th - Going for a ride'
				},
				{
					src: '/img/0507.jpg',
					caption: 'May 7th - Sam\'s famous- he\'s on TV'
				},
				{
					src: '/img/0508.jpg',
					caption: 'May 8th - Put Sam into storage'
				},
				{
					src: '/img/0509.jpg',
					caption: 'May 9th - Wash the cat'
				},
				{
					src: '/img/0510.jpg',
					caption: 'May 10th - Tea with dinner'
				},
				{
					src: '/img/0511.jpg',
					caption: 'May 11th - It\'s all business'
				},
				{
					src: '/img/0512.jpg',
					caption: 'May 12th - Dinner with a view'
				},
				{
					src: '/img/0513.jpg',
					caption: 'May 13th - Sam cubed'
				},
				{
					src: '/img/0514.jpg',
					caption: 'May 14th - Sam\'s in the closet'
				},
				{
					src: '/img/0515.jpg',
					caption: 'May 15th - Sam\'s final adventure'
				},
			]
		}
	}

	openLightbox(imgIndex) {
		this.setState({
			lbOpen: true,
			lbIndex: imgIndex
		})
	}

	gotoPrevious() {
		let prevIndex = this.state.lbIndex - 1;
		this.setState({
			lbIndex: prevIndex
		});
	}

	gotoNext() {
		let nextIndex = this.state.lbIndex + 1;
		this.setState({
			lbIndex: nextIndex
		});
	}

	closeLightbox() {
		this.setState({
			lbOpen: false,
		});
	}

	render() {
		return <div className='Calendar'>
			<Lightbox
        images={this.state.lbImages}
        isOpen={this.state.lbOpen}
        onClickPrev={this.gotoPrevious.bind(this)}
        onClickNext={this.gotoNext.bind(this)}
        onClose={this.closeLightbox.bind(this)}
        currentImage={this.state.lbIndex}
      />
			<div className='calendarWrapper'>
				<div className={'calendarWeek firstWeek'}>
					<div className={'calendarDay sunday inactive'}>
						<div className={'calendarDateNumber'}>Apr 22</div>
						<div className={'calendarDatePicture'}></div>
					</div>
					<div className={'calendarDay monday inactive'}>
						<div className={'calendarDateNumber'}>23</div>
						<div className={'calendarDatePicture'}></div>
					</div>
					<div className={'calendarDay tuesday inactive'}>
						<div className={'calendarDateNumber'}>24</div>
						<div className={'calendarDatePicture'}></div>
					</div>
					<div className={'calendarDay wednesday inactive'}>
						<div className={'calendarDateNumber'}>25</div>
						<div className={'calendarDatePicture'}></div>
					</div>
					<div className={'calendarDay thursday'} onClick={() => {this.openLightbox(0)}}>
						<div className={'calendarDatePicture'}>
							<img src='/img/0426.jpg' />
						</div>
						<div className={'calendarDateNumber'}>26</div>
					</div>
					<div className={'calendarDay friday'} onClick={() => {this.openLightbox(1)}}>
						<div className={'calendarDatePicture'}>
							<img src='/img/0427.jpg' />
						</div>
						<div className={'calendarDateNumber'}>27</div>
					</div>
					<div className={'calendarDay saturday'} onClick={() => {this.openLightbox(2)}}>
						<div className={'calendarDatePicture'}>
							<img src='/img/0428.jpg' />
						</div>
						<div className={'calendarDateNumber'}>28</div>
					</div>
				</div>
				<div className='calendarWeek'>
					<div className={'calendarDay sunday'} onClick={() => {this.openLightbox(3)}}>
						<div className={'calendarDatePicture'}>
							<img src='/img/0429.jpg' />
						</div>
						<div className={'calendarDateNumber'}>29</div>
					</div>
					<div className={'calendarDay monday'} onClick={() => {this.openLightbox(4)}}>
						<div className={'calendarDatePicture'}>
							<img src='/img/0430.jpg' />
						</div>
						<div className={'calendarDateNumber'}>30</div>
					</div>
					<div className={'calendarDay tuesday'} onClick={() => {this.openLightbox(5)}}>
						<div className={'calendarDatePicture'}>
							<img src='/img/0501.jpg' />
						</div>
						<div className={'calendarDateNumber'}>May 1</div>
					</div>
					<div className={'calendarDay wednesday'} onClick={() => {this.openLightbox(6)}}>
						<div className={'calendarDatePicture'}>
							<img src='/img/0502.jpg' />
						</div>
						<div className={'calendarDateNumber'}>2</div>
					</div>
					<div className={'calendarDay thursday'} onClick={() => {this.openLightbox(7)}}>
						<div className={'calendarDatePicture'}>
							<img src='/img/0503.jpg' />
						</div>
						<div className={'calendarDateNumber'}>3</div>
					</div>
					<div className={'calendarDay friday'} onClick={() => {this.openLightbox(8)}}>
						<div className={'calendarDatePicture'}>
							<img src='/img/0504.jpg' />
						</div>
						<div className={'calendarDateNumber'}>4</div>
					</div>
					<div className={'calendarDay saturday'} onClick={() => {this.openLightbox(9)}}>
						<div className={'calendarDatePicture'}>
							<img src='/img/0505.jpg' />
						</div>
						<div className={'calendarDateNumber'}>5</div>
					</div>
				</div>
				<div className='calendarWeek'>
					<div className={'calendarDay sunday'} onClick={() => {this.openLightbox(10)}}>
						<div className={'calendarDatePicture'}>
							<img src='/img/0506.jpg' />
						</div>
						<div className={'calendarDateNumber'}>6</div>
					</div>
					<div className={'calendarDay monday'} onClick={() => {this.openLightbox(11)}}>
						<div className={'calendarDatePicture'}>
							<img src='/img/0507.jpg' />
						</div>
						<div className={'calendarDateNumber'}>7</div>
					</div>
					<div className={'calendarDay tuesday'} onClick={() => {this.openLightbox(12)}}>
						<div className={'calendarDatePicture'}>
							<img src='/img/0508.jpg' />
						</div>
						<div className={'calendarDateNumber'}>8</div>
					</div>
					<div className={'calendarDay wednesday'} onClick={() => {this.openLightbox(13)}}>
						<div className={'calendarDatePicture'}>
							<img src='/img/0509.jpg' />
						</div>
						<div className={'calendarDateNumber'}>9</div>
					</div>
					<div className={'calendarDay thursday'} onClick={() => {this.openLightbox(14)}}>
						<div className={'calendarDatePicture'}>
							<img src='/img/0510.jpg' />
						</div>
						<div className={'calendarDateNumber'}>10</div>
					</div>
					<div className={'calendarDay friday'} onClick={() => {this.openLightbox(15)}}>
						<div className={'calendarDatePicture'}>
							<img src='/img/0511.jpg' />
						</div>
						<div className={'calendarDateNumber'}>11</div>
					</div>
					<div className={'calendarDay saturday'} onClick={() => {this.openLightbox(16)}}>
						<div className={'calendarDatePicture'}>
							<img src='/img/0512.jpg' />
						</div>
						<div className={'calendarDateNumber'}>12</div>
					</div>
				</div>
				<div className={'calendarWeek lastWeek'}>
					<div className={'calendarDay sunday'} onClick={() => {this.openLightbox(17)}}>
						<div className={'calendarDatePicture'}>
							<img src='/img/0513.jpg' />
						</div>
						<div className={'calendarDateNumber'}>13</div>
					</div>
					<div className={'calendarDay monday'} onClick={() => {this.openLightbox(18)}}>
						<div className={'calendarDatePicture'}>
							<img src='/img/0514.jpg' />
						</div>
						<div className={'calendarDateNumber'}>14</div>
					</div>
					<div className={'calendarDay tuesday'} onClick={() => {this.openLightbox(19)}}>
						<div className={'calendarDatePicture'}>
							<img src='/img/0515.jpg' />
						</div>
						<div className={'calendarDateNumber'}>15</div>
					</div>
					<div className={'calendarDay wednesday inactive'}>
						<div className={'calendarDateNumber'}>16</div>
						<div className={'calendarDatePicture'}></div>
					</div>
					<div className={'calendarDay thursday inactive'}>
						<div className={'calendarDateNumber'}>17</div>
						<div className={'calendarDatePicture'}></div>
					</div>
					<div className={'calendarDay friday inactive'}>
						<div className={'calendarDateNumber'}>18</div>
						<div className={'calendarDatePicture'}></div>
					</div>
					<div className={'calendarDay saturday inactive'}>
						<div className={'calendarDateNumber'}>19</div>
						<div className={'calendarDatePicture'}></div>
					</div>
				</div>
			</div>
		</div>;
	}
}
export default Calendar;
