import * as constants from '../constants/actions';


/******************************
*******  Synchronous  *********
******************************/

// export function authSave(token, tokenExpiry) {
//   return {
//     type: constants.AUTH_SAVE,
//     token,
//     tokenExpiry
//   };
// }

// // Exists outside auth reducer in root reducer
// export function authClear() {
// 	return {
// 		type: constants.AUTH_CLEAR
// 	}
// }


/******************************
*******  Asynchronous  ********
******************************/

