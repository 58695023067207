// Auth actions
export const AUTH_SAVE = 'AUTH_SAVE';
export const AUTH_CLEAR = 'AUTH_CLEAR';

// Common actions
export const COMMON_ALERT = 'COMMON_ALERT';
export const COMMON_CLOSE_ALERT = 'COMMON_CLOSE_ALERT';
export const COMMON_MODAL_RESIZE = 'COMMON_MODAL_RESIZE';

// Header actions
export const HEADER_TITLE = 'HEADER_TITLE';

// Login actions
// None

// User (global) actions
export const USER_LOGOUT = 'USER_LOGOUT';