import { combineReducers } from 'redux';

import auth from './auth';
import common from './common';
import header from './header';
import login from './login';


const appReducer = combineReducers({
  auth,
  common,
  header,
  login,
});

export default (state, action) => {
  if (action.type === 'AUTH_CLEAR') {
    state = undefined;
  }

  return appReducer(state, action);
}