import * as constants from '../constants/actions';
import * as storage from '../persistance/storage';

const initialState = {
  token: storage.get('token'),
  expiry: storage.get('tokenExpiry')
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case constants.AUTH_SAVE:
      return authSave(state, action);
    default:
      return state;
  }
}

function authSave(state, action) {
  return Object.assign({}, state, {
    token: action.token,
    tokenExpiry: action.tokenExpiry
  });
}