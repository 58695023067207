/********************************************************************************************
*****                                                                                   *****
*****    IMPORTANT! Changes here must also be set in /src/styles/common/_colors.scss    *****
*****                                                                                   *****
/*******************************************************************************************/


// Colors

export const ERROR_RED = '#EB3533';
