import 'babel-polyfill';

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {hot} from 'react-hot-loader'

import mui from 'material-ui';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import AntiTheme from '../../themes/antimatter';
import injectTapEventPlugin from 'react-tap-event-plugin';
injectTapEventPlugin();

import * as authActionCreators from '../../actions/auth';

class App extends React.Component {

	constructor() {
		super();
	}

	static childContextTypes = {
		muiTheme: PropTypes.object
	};

	static muiTheme = getMuiTheme(AntiTheme);

	render() {
		return <MuiThemeProvider muiTheme={this.muiTheme}>{this.props.children}</MuiThemeProvider>;
	}
}
export default hot(module)(App);

function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}

export const AppContainer = connect(
  mapStateToProps,
  authActionCreators
)(App);