import React from 'react';
import ReactDOM from 'react-dom';
import {Router, Route, IndexRoute, browserHistory} from 'react-router';

import {createStore} from 'redux';
import {Provider} from 'react-redux';

import isLoggedIn from './utils/auth';
import configureStore from './utils/configureStore';

import {AppContainer} from './components/common/App';
import Dashboard from './components/views/dashboard/Dashboard';

const store = configureStore();

require('./styles/main.scss');


ReactDOM.render(
	<Provider store={store}>
	  <Router onUpdate={() => window.scroll(0, 0)} history={browserHistory}>
	    <Route path='/' component={AppContainer}>
	    	<IndexRoute component={Dashboard} />
	    </Route>
	  </Router>
  </Provider>,
  document.getElementById('samthecat-app')
);

module.hot.accept();